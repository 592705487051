



























































import { FormBlock } from '@/app/components';
import { defineComponent } from '@vue/composition-api';
import { ValidationObserver } from 'vee-validate';

export default defineComponent({
    name: 'UserPreferences',
    components: {
        ValidationObserver,
        FormBlock,
    },
    props: {
        defaultPreferences: {
            type: Object,
            required: true,
        },
        loading: {
            type: Boolean,
        },
    },

    setup() {
        // Modify event title to a readable label
        const getEventTitle = (event: string) => {
            switch (event) {
                // contracts
                case 'contract.created':
                    return 'When a request is created';
                case 'contract.accepted':
                case 'contract.activated':
                case 'contract.rejected':
                case 'contract.expired':
                case 'contract.extended':
                case 'contract.revised':
                case 'contract.reactivated':
                    return `When a contract is  ${event.split('.').slice(1).join(' ')}`;
                case 'contract.draft.signed':
                    return 'When a draft contract is created';
                case 'contract.negotiation':
                    return `When a contract is being negotiated`;
                case 'contract.bundle.rejected':
                case 'contract.bundle.activated':
                    return `When a bundle contract is ${event.split('.').slice(2).join(' ')}`;
                // workflow
                case 'workflow.service.completed':
                    return 'When an analytics pipeline is successfully executed';
                case 'workflow.service.failed':
                    return 'When an analytics pipeline execution fails';
                case 'workflow.service.skipped':
                    return 'When an analytics pipeline execution has been skipped';
                // dcj
                case 'dcj.service.completed':
                    return 'When a data check-in pipeline is successfully executed';
                case 'dcj.service.failed':
                    return 'When a data check-in pipeline execution fails';
                case 'dcj.service.skipped':
                    return 'When a data check-in pipeline execution has been skipped';
                // model suggestions
                case 'concept.created':
                case 'concept.approved':
                case 'concept.rejected':
                    return `When a suggestion is  ${event.split('.').slice(1).join(' ')}`;
                // administations
                case 'admin.user.deleted':
                    return `When a user is deleted`;
                case 'admin.organisation.deleted':
                    return `When an organisation is deleted`;
                default:
                    break;
            }

            return '';
        };

        return { getEventTitle };
    },
});
